<template>
  <div class="row">
    <div class="col-lg-12">
      <div class="card">
        <div class="card-block">
          <div class="h4 m-0">{{ $t('general.step1') }}</div>
          <small class="text-muted">{{ $t('general.basicParameters') }}</small>
          <form action="">
            <div class="form-group">
              <label for="name">{{ $t('general.name') }}</label>
              <input
                :data-vv-as="$t('general.name')"
                type="text"
                name="name"
                id="name"
                :placeholder="$t('radius.namePlaceholder')"
                class="form-control"
                v-validate="'required|max:32'"
                :class="{ input: true, 'is-danger': errors.has('name') }"
                v-model="newRadiusData.name"
              />
              <span v-show="errors.has('name')" class="help is-danger">{{ errors.first('name') }}</span>
            </div>

            <div class="form-group">
              <label for="ip address">
                {{ $t('radius.addressField') }}
                <span class="text-muted small">({{ $t('radius.addressFieldHint') }})</span>
              </label>
              <input
                :data-vv-as="$t('radius.addressField')"
                type="text"
                name="ip address"
                id="ip address"
                :placeholder="$t('radius.ipPlaceholder')"
                class="form-control"
                v-validate="'required|ipv4orDomainName'"
                :class="{ input: true, 'is-danger': errors.has('ip address') }"
                v-model="newRadiusData.hostname"
              />
              <span v-show="errors.has('ip address')" class="help is-danger">{{ errors.first('ip address') }}</span>
            </div>

            <div class="form-group">
              <label for="location">{{ $t('general.location') }}</label>
              <select v-model="newRadiusData.base_location" name="location" class="form-control" id="location">
                <option v-for="location in locationsList" :key="location.id" :value="location.id">
                  {{ location.name }}
                </option>
              </select>
            </div>

            <button
              type="button"
              class="btn btn-outline-primary float-right radius-wizard-next-button"
              @click="setNewRadiusData"
              :disabled="errors.any()"
            >
              {{ $t('general.next') }}
            </button>

            <button
              type="button"
              class="btn btn-outline-secondary float-left radius-wizard-back-button"
              @click="$parent.prevStep"
            >
              {{ $t('general.back') }}
            </button>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import commonService from '../../services/commonService';
import helpers from '../../helpers';

export default {
  name: 'RadiusStep1',
  data() {
    return {
      newRadiusData: {
        hostname: '',
        name: '',
        base_location: ''
      }
    };
  },
  computed: {
    locationsList() {
      // return this.$store.state.locationsList;
      // return helpers.sortLocationsList(this.$store.state.locationsList);
      return this.$store.getters.locationsListSorted;
    }
  },
  methods: {
    setNewRadiusData() {
      this.$validator
        .validateAll({
          'ip address': this.newRadiusData.hostname,
          name: this.newRadiusData.name
        })
        .then(
          (result) => {
            if (result) {
              this.$store.commit('setNewRadiusData', this.newRadiusData);
              this.$parent.nextStep();
            }
          },
          () => {
            console.warn('Validation failed');
          }
        );
    },
    locationName(id) {
      const showLocationFromId = commonService.showLocationFromId(id);
      return showLocationFromId;
    }
  },
  created() {
    this.newRadiusData = JSON.parse(JSON.stringify(this.$store.state.newRadiusData));
    this.newRadiusData.base_location = this.$store.state.userData.base_location;
  }
};
</script>
<style scoped lang="scss">
.radius-wizard-next-button {
  top: 10px;
  position: relative;
}

.radius-wizard-back-button {
  top: 10px;
  position: relative;
}
</style>

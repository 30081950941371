<template>
  <div class="row">
    <div class="col-lg-12">
      <div class="card">
        <div class="card-block">
          <div class="h4 m-0">{{ $t('general.summary') }}</div>
          <small class="text-muted">{{ $t('general.summaryConfirmation') }}</small>

          <form action="" class="mt-1">
            <p class="text-info">
              {{ $t('general.name') }}:
              <span class="text-muted">{{ newRadiusData.name }}</span>
            </p>
            <p class="text-info">
              {{ $t('radius.addressField') }}:
              <span class="text-muted">{{ newRadiusData.hostname }}</span>
            </p>
            <p class="text-info">
              {{ $t('general.baseLocation') }}:
              <span class="text-muted">{{ locationName(newRadiusData.base_location) }}</span>
            </p>
            <p class="text-info">
              {{ $t('radius.password') }}:
              <span class="text-muted">{{ newRadiusData.secret }}</span>
            </p>
            <p class="text-info">
              {{ $t('radius.authPort') }}:
              <span class="text-muted">{{ newRadiusData.auth_port }}</span>
            </p>
            <p class="text-info">
              {{ $t('radius.acctPort') }}:
              <span class="text-muted">{{ newRadiusData.acc_port }}</span>
            </p>
            <p class="text-info">
              {{ $t('radius.daeClient') }}:
              <span class="text-muted">{{ newRadiusData.dae_client }}</span>
            </p>
            <p class="text-info">
              {{ $t('radius.daePort') }}:
              <span class="text-muted">{{ newRadiusData.dae_port }}</span>
            </p>
            <p class="text-info">
              {{ $t('radius.daeSecret') }}:
              <span class="text-muted dae-secret-last-step">{{ newRadiusData.dae_secret }}</span>
            </p>
            <p class="text-info">
              {{ $t('radius.local') }}:
              <span class="text-muted">
                {{ newRadiusData.is_local ? `${$t('general.yes')}` : `${$t('general.no')}` }}
              </span>
            </p>
            <p class="text-info">
              {{ $t('radius.portal') }}:
              <span class="text-muted">
                {{ newRadiusData.is_portal ? `${$t('general.yes')}` : `${$t('general.no')}` }}
              </span>
            </p>

            <button
              type="button"
              class="btn btn-outline-primary float-right radius-wizard-next-button"
              @click="confirmRadiusCreation"
            >
              {{ $t('general.confirm') }}
            </button>

            <button
              type="button"
              class="btn btn-outline-danger float-left radius-wizard-back-button"
              @click="$parent.prevStep"
            >
              {{ $t('general.back') }}
            </button>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import VueNotifications from 'vue-notifications';
import radiusService from '../../services/radiusService';
import radiusPollingService from '../../services/radiusPollingService';
import commonService from '../../services/commonService';

export default {
  name: 'RadiusStep3',
  data() {
    return {
      newRadiusData: {
        hostname: '',
        name: '',
        base_location: '',
        secret: '',
        auth_port: '',
        acc_port: ''
      }
    };
  },
  methods: {
    confirmRadiusCreation() {
      const transformedQuery = { ...this.newRadiusData };
      transformedQuery.port = parseInt(transformedQuery.port);

      this.axios.post(`${window.RESTAPI || ''}/api/radius`, { action: 'C', items: { 0: transformedQuery } }).then(
        (response) => {
          if (response.data.status === 'error') {
            VueNotifications.error({ message: response.data.description });
          }
          this.$store.commit('clearNewRadiusData');
          radiusPollingService.hadleOperationResponse(response.data.data.items);
          radiusService.getRadius(this);
          if (this.$store.state.stepNameForReturningToCreateWLANWizard) {
            setTimeout(() => {
              this.$router.push({name: 'wlanWizardV2'})
            }, 1000);
          } else if (this.$store.state.returnToEditWlan) {
            setTimeout(() => {
              this.$router.push({name: 'Wlans'})
            }, 1000);
          } else {
            this.$parent.nextStep();
          }
          // this.$parent.nextStep();
        },
        (err) => {
          VueNotifications.error({ message: err });
          this.error = err;
        }
      );
    },
    locationName(id) {
      const showLocationFromId = commonService.showLocationFromId(id);
      return showLocationFromId;
    }
  },
  created() {
    this.newRadiusData = JSON.parse(JSON.stringify(this.$store.state.newRadiusData));
  }
};
</script>
<style scoped lang="scss">
.radius-wizard-next-button {
  top: 10px;
  position: relative;
}

.radius-wizard-back-button {
  top: 10px;
  position: relative;
}
.dae-secret-last-step {
  word-wrap: break-word;
}
</style>

<template>
  <div class="row">
    <div class="col-lg-12">
      <div class="card">
        <div class="card-block">
          <div class="h4 m-0">{{ $t('general.step2') }}</div>
          <small class="text-muted">{{ $t('radius.wizardAdvancedParameters') }}</small>
          <form action="">
            <div class="form-group">
              <label for="secret">{{ $t('radius.password') }}</label>
              <input
                type="text"
                name="secret"
                id="secret"
                :placeholder="$t('radius.secretPlaceholder')"
                class="form-control"
                v-validate="'required|max:32'"
                :class="{ input: true, 'is-danger': errors.has('secret') }"
                v-model="newRadiusData.secret"
              />
              <span v-show="errors.has('secret')" class="help is-danger">{{ errors.first('secret') }}</span>
            </div>

            <div class="form-group">
              <label for="auth">{{ $t('radius.authPort') }}</label>
              <input
                type="text"
                name="auth port"
                id="auth"
                :placeholder="$t('radius.authPortPlaceholder')"
                class="form-control"
                v-validate="'required|numeric|between:1,65535'"
                :class="{ input: true, 'is-danger': errors.has('auth port') }"
                v-model="newRadiusData.auth_port"
              />
              <span v-show="errors.has('auth port')" class="help is-danger">{{ errors.first('auth port') }}</span>
            </div>

            <div class="form-group">
              <label for="acct">{{ $t('radius.acctPort') }}</label>
              <input
                type="text"
                name="acct port"
                id="acct"
                :placeholder="$t('radius.acctPortPlaceholder')"
                class="form-control"
                v-validate="'required|numeric|between:1,65535'"
                :class="{ input: true, 'is-danger': errors.has('acct port') }"
                v-model="newRadiusData.acc_port"
              />
              <span v-show="errors.has('acct port')" class="help is-danger">{{ errors.first('acct port') }}</span>
            </div>

            <h6 class="mt-3">{{ $t('radius.daeSettings') }}</h6>
            <!--                  dae_client-->
            <div class="form-group mt-1">
              <label for="dae_client">
                {{ $t('radius.daeClient') }}
                <info :content="$t('radius.daeClientHintInfoMsg')" />
              </label>
              <input
                :data-vv-as="$t('radius.daeClient')"
                type="text"
                name="dae_client"
                id="dae_client"
                :placeholder="$t('radius.daeClientPlaceholder')"
                class="form-control"
                v-validate="'radiusDaeClient'"
                :class="{ input: true, 'is-danger': errors.has('dae_client') }"
                v-model="newRadiusData.dae_client"
                data-vv-validate-on="change"
                @input="daeClientInputHandler"
              />
              <span v-show="errors.has('dae_client')" class="help is-danger">{{ errors.first('dae_client') }}</span>
            </div>
            <!--                  dae_port-->
            <div class="form-group mt-1">
              <label for="dae_client">
                {{ $t('radius.daePort') }}
                <info :content="$t('radius.daePortHintInfoMsg')" />
              </label>
              <input
                :data-vv-as="$t('radius.daePort')"
                type="text"
                name="dae_port"
                id="dae_port"
                :placeholder="$t('radius.daePortPlaceholder')"
                class="form-control"
                v-validate="'numeric|between:1,65535'"
                :class="{ input: true, 'is-danger': errors.has('dae_port') }"
                v-model="newRadiusData.dae_port"
                data-vv-validate-on="input"
              />
              <span v-show="errors.has('dae_port')" class="help is-danger">{{ errors.first('dae_port') }}</span>
            </div>

            <!--                  dae_secret-->
            <div class="form-group mt-1">
              <label for="dae_client">
                {{ $t('radius.daeSecret') }}
                <info :content="$t('radius.daeSecretHintInfoMsg')" />
              </label>
              <textarea
                name="dae_secret"
                id="dae_secret"
                :placeholder="$t('radius.daeSecretPlaceholder')"
                class="form-control"
                :class="{ input: true, 'is-danger': errors.has('dae_secret') }"
                v-model="newRadiusData.dae_secret"
              />
              <span v-show="errors.has('dae_secret')" class="help is-danger">{{ errors.first('dae_secret') }}</span>
            </div>

            <div class="form-group mt-3">
              <Switch-component
                v-model="newRadiusData.is_local"
                @change="newRadiusData.is_portal = false"
                :label="$t('radius.local')"
                id="radius-local-new"
                key="radius-local-new"
              />
            </div>
            <div class="form-group">
              <Switch-component
                v-model="newRadiusData.is_portal"
                :disabled="newRadiusData.is_local"
                :label="$t('radius.portal')"
                id="radius-portal-new"
                key="radius-portal-new"
              />
            </div>

            <!--auth/acct/both-->

            <button
              type="button"
              class="btn btn-outline-primary float-right radius-wizard-next-button"
              @click="setNewRadiusData"
              :disabled="errors.any()"
            >
              {{ $t('general.next') }}
            </button>

            <button
              type="button"
              class="btn btn-outline-danger float-left radius-wizard-back-button"
              @click="$parent.prevStep"
            >
              {{ $t('general.back') }}
            </button>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SwitchComponent from '../../components/Universal/Switch-component.vue';
import Info from '../../components/Universal/info-icon.vue';

export default {
  name: 'RadiusStep2',
  components: {
    SwitchComponent,
    Info
  },
  data() {
    return {
      newRadiusData: {
        secret: '',
        auth_port: '1812',
        acc_port: '1813',
        dae_client: '',
        dae_port: '3799',
        dae_secret: ''
      }
    };
  },
  methods: {
    daeClientInputHandler(e) {
      // очищаем ошибку dae_client, если она есть, каждый раз когда начанаем вводить dae_client
      if (Object.prototype.hasOwnProperty.call(this, 'errors')) {
        if (this.errors.has('dae_client')) {
          // console.log('has dae_client error');
          this.errors.remove('dae_client');
        }
      }
    },
    setNewRadiusData() {
      if (this.newRadiusData.dae_port === '') {
        // если ничего не ввели ставим в значение по умолчанию
        this.newRadiusData.dae_port = '3799';
      }
      this.$validator
        .validateAll({
          secret: this.newRadiusData.secret,
          'auth port': this.newRadiusData.auth_port,
          'acct port': this.newRadiusData.acc_port
        })
        .then(
          (result) => {
            if (result) {
              this.$store.commit('setNewRadiusData', this.newRadiusData);
              this.$parent.nextStep();
            }
          },
          () => {
            console.warn('Validation failed');
          }
        );
    }
  },
  created() {
    this.newRadiusData = JSON.parse(JSON.stringify(this.$store.state.newRadiusData));
  }
};
</script>
<style scoped lang="scss">
.radius-wizard-next-button {
  top: 10px;
  position: relative;
}

.radius-wizard-back-button {
  top: 10px;
  position: relative;
}
</style>
